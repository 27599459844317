$(() => {
    /*
    * When the picker passes a zoneID to the 
    */
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)

    // Do we have zoneID urlParam and are on the event detail screen?
    
    if (urlParams.has('zone-id') && $('.tn-event-detail').length) {
        console.log('hi');
        const zoneID = urlParams.get('zone-id');
        const $selectedZone = $('.tn-ticket-selector__zone-selector').find('[data-zone-id="' + zoneID + '"]');
        // hide all
        $('.tn-zone-selector__option').hide();
        // show selected (parent as nested)
        $selectedZone.parent().parent().show();
        $selectedZone.trigger('click');
        $selectedZone.before('<p class="select-tickets-label">Your chosen time</p>');
        $('.tn-ticket-selector__pricetype-selector').before('<p class="select-tickets-label">Select your tickets</p>');

    }

  })
  