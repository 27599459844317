/**
 * Insert before
 * Find the parent node and insert the new node before
 * @param {*} referenceNode
 * @param {*} newNode
 */
export const insertBefore = (referenceNode, newNode) => {
  referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling)
}

/**
 * Leading zeros
 * Add leading zeros to a string to make the string
 * a required length
 * @param {*} string
 * @param {*} requiredLength
 * @returns
 */
export const leadingZeros = (string, requiredLength) => {
  return ('00000000' + string).substr(-requiredLength)
}
