/**
 * Memberships
 * Web Components configuration for the memberships page
 * account/memberships
 *
 * Components:
 * + Digital Wallet - Memberships Download Bar
 */

// Page URL
const pageURL = window.location.href

// Is Test
const isTest = (pageURL.includes('test.tnhs.cloud') || pageURL.includes('dev0.tnhs.cloud'))

// Upcoming memberships pge
if (pageURL.includes('account/memberships')) {
  // If - Membership summary list
  if (document.getElementsByClassName('tn-membership-summary__list')[0]) {
    // Session Detail
    const session = window.dataLayer.find(function (e) { return e.hasOwnProperty('session') }).session
    const encryptedSessionKey = btoa(session.key)

    // Loop - Memberships
    const memberships = [...document.getElementsByClassName('tn-account-section-list__item')]
    memberships.forEach((event) => {
      /**
       * Digital Wallet - Memberships Download Bar
       */

      // Create Element
      const digitalWalletMembershipsDownloadBar = document.createElement('twc-digital-wallets-memberships-download-bar')

      // Membership summary
      const membershipSummaryDiv = event.querySelector('.tn-membership-summary')

      // Add dataset
      digitalWalletMembershipsDownloadBar.dataset.isTest = isTest
      digitalWalletMembershipsDownloadBar.dataset.passType = 'memberships'
      digitalWalletMembershipsDownloadBar.dataset.membershipId = membershipSummaryDiv.getAttribute('data-tn-membership-id')
      digitalWalletMembershipsDownloadBar.dataset.tnew = encryptedSessionKey

      // Append Memberships Download Bar
      const membershipSummaryDetailsDiv = event.querySelector('.tn-membership-summary__details-container')
      membershipSummaryDetailsDiv.append(digitalWalletMembershipsDownloadBar)
    })
  }
}
