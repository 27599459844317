/**
 * Upcoming Events
 * Web Components configuration for the upcoming events page
 * account/upcomingevents
 *
 * Components:
 * + Digital Wallet - Tickets Download Bar
 */

// Page URL
const pageURL = window.location.href

// Is Test
const isTest = (pageURL.includes('test.tnhs.cloud') || pageURL.includes('dev0.tnhs.cloud'))

// Upcoming events pge
if (pageURL.includes('account/upcomingevents') && isTest) {
  // If - Upcoming Event List
  if (document.getElementsByClassName('tn-upcoming-event__list')[0]) {
    // Session Detail
    const session = window.dataLayer.find(function (e) { return e.hasOwnProperty('session') }).session
    const encryptedSessionKey = btoa(session.key)

    // Loop - Upcoming Events
    const upcomingEvents = [...document.getElementsByClassName('tn-upcoming-event')]
    upcomingEvents.forEach((event) => {
      // Ensure consistency across event listings
      event.classList.add('tn-upcoming-event--primary')

      /**
       * Digital Wallet - Tickets Download Bar
       */

      // Create Element
      const digitalWalletTicketsDownloadBar = document.createElement('twc-digital-wallets-tickets-download-bar')

      // Event title
      let eventTitle = ''
      const eventTitleElement = [...event.getElementsByClassName('tn-upcoming-event__title')][0]
      if (eventTitleElement) {
        eventTitle = eventTitleElement.innerHTML.trim()
      }

      // Instance date/time
      let instanceDateTime = ''
      const instanceDateTimeElement = [...event.getElementsByClassName('tn-upcoming-event__date-time')][0]
      if (instanceDateTimeElement && instanceDateTimeElement.innerHTML) {
        const rawDate = instanceDateTimeElement.innerHTML.trim().toLowerCase().replace(/am|pm/, '')
        instanceDateTime = new Date(rawDate).getTime()
      }

      // Add dataset
      digitalWalletTicketsDownloadBar.dataset.isTest = isTest
      digitalWalletTicketsDownloadBar.dataset.passType = 'tickets'
      digitalWalletTicketsDownloadBar.dataset.instanceId = event.getAttribute('data-tn-performance-id')
      digitalWalletTicketsDownloadBar.dataset.instanceDateTime = instanceDateTime
      digitalWalletTicketsDownloadBar.dataset.eventTitle = eventTitle
      digitalWalletTicketsDownloadBar.dataset.tnew = encryptedSessionKey

      // Append Tickets Download Bar
      const eventDetailsDiv = event.querySelector('.tn-upcoming-event__details')
      eventDetailsDiv.append(digitalWalletTicketsDownloadBar)
    })
  }
}
