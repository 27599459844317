const pageURL = window.location.href
if (pageURL.includes('account/logout')) {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)

    if (urlParams.has('ReturnURL')) {
        try {
            // redirect back to return URL if no errors thrown for invalid URL
            const returnUrl = new URL(urlParams.get('ReturnURL'))
            window.location = returnUrl.href
        } catch (e) {
            console.log(e)
        }

    }
}
