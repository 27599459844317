/* eslint-disable no-new */
/* eslint-disable no-undef */

/**
 * Customisation - Memberships QR code
 * Add a QR code to display next to a membership on the
 * customer account
 */
import { insertBefore, leadingZeros } from './utilities'

// Memberships page
const pageURL = window.location.href
if (pageURL.includes('account/memberships')) {
  // Session data layer
  const session = dataLayer.find((e) => {
    return e.hasOwnProperty('session')
  }).session

  // Session data model
  const sessionDataModel = {
    customerNumber: session.customer.id,
    modeOfSale: session.modeOfSale
  }

  // If customer
  if (sessionDataModel.customerNumber !== 0) {
    // Memberships
    const membershipSummaryContainers = [...document.getElementsByClassName('tn-membership-summary__details-container')]
    for (const membership of membershipSummaryContainers) {
      // Membership ID
      const membershipId = membership.parentElement.dataset.tnMembershipId

      /**
       * Build membership QR code container
       * Add a container div
       */
      const membershipQRCodeContainer = document.createElement('div')
      membershipQRCodeContainer.setAttribute('class', ['c-customisation', 'c-customisation__membership-qr-code-block'])
      const membershipQRCodePlacement = document.createElement('div')
      membershipQRCodePlacement.setAttribute('id', `qrcode${membershipId}`)
      membershipQRCodeContainer.appendChild(membershipQRCodePlacement)

      // Add to membership block
      insertBefore(membership, membershipQRCodeContainer)

      // QR code value
      const QRCustomerId = sessionDataModel.customerNumber
      const QRCodeValue = `C${QRCustomerId}`

      // Build QR code
      new QRCode(`qrcode${membershipId}`, {
        text: QRCodeValue,
        width: 128,
        height: 128,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H
      })
    }
  }
}
