// Customisations
import './aria-disclosure.js'
import './timedEntry.js'
import './logoutRedirect.js'
import './signup-preferences.js'
import './zendesk-mod.js'
import './customisations/membership-qr-codes'
import './customisations/tracking'

// TNEW Web Components
import './tnew-web-components/index'

$(() => {
  console.log('tnew web components 1.0.1')
  const pageURL = window.location.href.toLowerCase()

  // if logged in, then add body class
  if ($('.tn-subnav-component .tn-logged-in').length) {
    $('body').addClass('state--logged-in');
  } else {
    $('body').addClass('state--logged-out');
    const loginURL = $('.tn-login-link a').attr('href');
    $('.c-global-header__login-link').attr('href', loginURL);
  }

  /*
  * REARRANGE ITEMS IN SYOS
  */

  // Define Media Query
  const desktopMediaQuery = window.matchMedia('(max-width: 62.5em)');

  // Function to position "Change Date" dropdown based on screen size
  const additionalEventsPosition = () => {
    if (desktopMediaQuery.matches) {
      $('.tn-event-detail__additional-events-container').appendTo('.tn-event-detail__main-container');
    } else {
      $('.tn-event-detail__additional-events-container').appendTo('.tn-ticketing-mode-change');
    }
  };

  // Reorder components in seat selection
  $('.tn-ticketing-mode-change__content').appendTo('.tn-event-detail__performance-details-container');
  $('.tn-additional-events__continue-shopping-link').appendTo('.tn-event-detail__main-container');
  additionalEventsPosition();

  // Wrap components
  $('.tn-event-detail__performance-details-container, .tn-ticketing-mode-change').wrapAll('<div class="tn-event-details"></div>');

  // Remove classes from "Continue Shopping"
  $('.tn-additional-events__continue-shopping-link').removeClass('btn btn-primary');

  // Listen for window resize to move "Change Date" dropdown if necessary
  $(window).resize(additionalEventsPosition);

})
