/** @format */

document.addEventListener('click', function(event) {
    // Only run on elements that have the [data-disclosure] attribute
    // If the event.target doesn't have the attribute, return ends the callback function
    if (!event.target.hasAttribute('data-disclosure')) return;

    console.log('#' + event.target.getAttribute('aria-controls'));

  
    // Get the content to toggle
    // If no matching content is found, end the function with return
    var content = document.querySelector(
      '#' + event.target.getAttribute('aria-controls'),
    );
    if (!content) return;


  
    // If the content is visible, hide it
    // Otherwise, show it
    if (event.target.getAttribute('aria-expanded') === 'true') {
      hideTarget();
    } else {
      showTarget();
    }
  
    function showTarget() {
      event.target.setAttribute('aria-expanded', true);
      content.removeAttribute('hidden');
      const reflow = content.offsetHeight;
      content.classList.add('is-open');
    }
  
    function hideTarget() {
      event.target.setAttribute('aria-expanded', false);
      content.setAttribute('hidden', '');
      const reflow = content.offsetHeight;
      content.classList.remove('is-open');
    }
  });
  