const pageURL = window.location.href
if (pageURL.includes('cart/receipt')) {

    const fullName = document.querySelector('.tn-patron-billing-information .tn-line1')?.innerText.split(" ")
    // The is a poor English-centric guess, but we don't have any more info available on this page
    const firstName = fullName.shift()
    const lastName = fullName.join(" ")

    const addressLines = document.querySelectorAll('.tn-patron-billing-information .tn-line2, .tn-patron-billing-information .tn-line3, .tn-patron-billing-information .tn-line4, .tn-patron-billing-information .tn-line5')
    const address = Array.from(addressLines).map(item => {return item.innerText})
    const postCodeLocation = address[2].match(/([A-Za-z]{1,2}\d{1,2})(\s?(\d?\w{2}))?/)
    const postCode = postCodeLocation[0]
    const cityState = address[2].substring(0, postCodeLocation.index - 1).split(", ")

    const email = document.querySelector('.tn-patron-billing-information .tn-electronic-address')?.innerText
    
    // Return the phone number with +44 or leading 0 removed
    const phoneNumber = document.querySelector('.tn-patron-billing-information .tn-phone')?.innerText.replace(/(^(\+44)?0?)?/, '')

    // Save the user data to the dataLayer
    let user_data = {};
    user_data.first_name = firstName
    user_data.last_name = lastName
    user_data.email_address = email
    user_data.phone_number = phoneNumber.replace()
    user_data.city = cityState[0]
    user_data.state = cityState[1]
    user_data.country = address[3]
    user_data.postal_code = postCode
    dataLayer.push({'user_data': user_data})
}
