$(() => {
    /*
    * When the site links to account/create/brief with a mailing list parameter
    */
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    
    if (urlParams.has('signup') && $('.tn-account-create-brief-page').length) {

        console.log('preferences');
        const signupType = urlParams.get('signup');
        console.log(signupType);
        let contentTitle, contentIntro;

        // Check selected item
        $('.tn-interests').find('.tn-interest-'+signupType).prop('checked', true);

        // Hide the interest and contact permission panel
        $('#contactPermissionsApp').hide();
        $('.tn-interests').hide();

        setTimeout(function(){
            // set contact permission to Yes
            $('.tn-component-contact-permissions__settings .tn-component-contact-permissions__settings__item:nth-of-type(1) input[value="Y"]').prop('checked', true);
        
        }, 200); 

        if (signupType == 2420) {
            // Accessible events
            contentTitle = 'Subscribe for updates on accessible events';
            contentIntro = 'Everyone has a right to enjoy the arts – subscribe for the latest news on accessible events and assisted formats at Southbank Centre.';
        }
        if (signupType == 2713) {
            // Arts & wellbeing
            contentTitle = 'Subscribe for Arts & Wellbeing updates';
            contentIntro = 'Stay inspired with regular news and updates on our Arts & Wellbeing projects nurturing connection and creativity in our communities.';
        }

        if (signupType == 1978) {
            // Classical music
            contentTitle = 'Subscribe for our free Classical Music newsletter';
            contentIntro = 'Discover classical music in the 21st century with updates on the exceptional artists, orchestras and ensembles at the Southbank Centre.';
        }
        if (signupType == 1981) {
            // Education & schools events
            contentTitle = 'Subscribe for our free Schools and Education newsletter';
            contentIntro = 'Sign up to our schools mailing list to get all the latest on our arts-led learning projects, workshops and teachers events, direct to your inbox.';
        }
        if (signupType == 2044) {
            // Young people
            contentTitle = 'Subscribe for our young people newsletter';
            contentIntro = 'Subscribe for the latest events and creative opportunities for young people at the Southbank Centre.';
        }
        if (signupType == 1980) {
            // Family
            contentTitle = 'Subscribe for our families newsletter';
            contentIntro = 'Stay in-the-loop on all our family-friendly events and activities with our free email newsletter.';
        }
        if (signupType == 2808) {
            // Free
            contentTitle = 'Subscribe for updates on free things to do';
            contentIntro = 'More than 50% of our events are free; make sure you don’t miss any of them.';
        }
        if (signupType == 1982) {
            // Gigs
            contentTitle = 'Subscribe for our gigs newsletter';
            contentIntro = 'Discover the next big thing at futuretense and be first to know who’s curating Meltdown.';
        }
        if (signupType == 1984) {
            // Literature, talks & poetry
            contentTitle = 'Subscribe for our literature & poetry newsletter';
            contentIntro = 'From outspoken authors to the poets of Out-Spoken, keep on top of all things literary.';
        }
        if (signupType == 2447) {
            // NPL
            contentTitle = 'Subscribe for updates from National Poetry Library';
            contentIntro = 'Subscribe for email updates on poetry events, new collection items, competitions, publishers and magazines, direct to your inbox.';
        }
        if (signupType == 1979) {
            // Performance & dance
            contentTitle = 'Subscribe for our performance & dance newsletter';
            contentIntro = 'Whether you’re into stage shows or stand-up sets be sure to stay in touch.';
        }
        if (signupType == 1983) {
            // Visual arts
            contentTitle = 'Subscribe for visual arts updates';
            contentIntro = 'Get added insight into the latest big name exhibition and commissions from  Hayward Gallery.';
        }
        if (signupType == 2872) {
            // Weekly highlights
            contentTitle = 'Subscribe for our weekly highlights';
            contentIntro = 'Never miss a moment—subscribe for your weekly fix of classical music, gigs, visual art, performance and dance, literature and poetry.';
        }


        setTimeout(function(){
            $('.tn-heading').text(contentTitle);
            $('.tn-heading-sub-text').text(contentIntro);
            $('.tn-heading-sub-text').after('<p><i class="far fa-newspaper"></i> <a href="https://www.southbankcentre.co.uk/newsletter">View all our newsletters</a></p>');
        }, 100); 

    }

  })
  