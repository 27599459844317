/** @format */

window.zESettings = {
    webWidget: {
        chat: {
            suppress: true // Hide the default launcher
        }
    }
};

(function () {
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=51f4c7bb-d99a-4c03-b92a-ff75eb2791b1';
    document.getElementsByTagName('head')[0].appendChild(script);
})();


$(document).ready(function () {
    $('.c-btn--chat').on('click', function () {
        // Show the Zendesk chat widget
        zE('webWidget', 'open');
    });
});